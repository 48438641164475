<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <div class="row">
                        <div class="col-12">
                            <BreadCrumbDate defaultType="YEAR" namePage="Yearly Report" :hideToday="true" :hideMonth="true" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-3">
                          <CompanyDetail />
                          <SelectPlant />
                          <YearlyDetail/>
                          <NoFullLoad />
                        </div>
                        <div class="col-12 col-md-9">
                          <ProductTable/>
                          <ComparePvsystChart/>
                          <ComparePvsystLineChart/>
                          <GraphsNotCal/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
// @ is an alias to /src
import BreadCrumbDate from '@/components/main/BreadCrumbDate.vue';
import CompanyDetail from '@/components/main/CompanyDetail.vue';
import SelectPlant from '@/components/main/SelectPlant.vue';
import YearlyDetail from '@/components/yearly/YearlyDetail.vue';
import NoFullLoad from '@/components/yearly/NoFullLoad.vue';
import ProductTable from '@/components/yearly/ProductTable.vue';
import ComparePvsystChart from '@/components/yearly/ComparePvsystChart.vue';
import ComparePvsystLineChart from '@/components/yearly/ComparePvsystLineChart.vue';
import GraphsNotCal from '@/components/yearly/GraphsNotCal.vue';
export default {
  name: 'Yearly',
  components: {
    BreadCrumbDate,
    CompanyDetail,
    SelectPlant,
    YearlyDetail,
    NoFullLoad,
    ProductTable,
    ComparePvsystChart,
    ComparePvsystLineChart,
    GraphsNotCal
  },
  data: function () {
    return {
        selectedMonth:null
      }
    },
  mounted() {
    this.$store.dispatch('display/SET_pageName',"Yearly Report")
  },
  methods: {
 
  }
}

</script>