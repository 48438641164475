<template>
<div class="row card ">
    <div class="col-12">
      <div class="row">
        <div class="col-12 p-3">
          <p class="title-card">{{ t('graphpvs',{},{locale:getLocal}) }}</p>
        </div>
        <div class="col-12">
          <hr class="m-0">
        </div>
        <div class="col-12 mt-3">
          <div class="cashflow-display d-flex mb-0">
              <div class="cashflow-display__single">
                  <span class="cashflow-display__title">{{ t('pvsbalance',{},{locale:getLocal}) }}</span>
                  <h2 class="font-color-main cashflow-display__amount color-primary">{{Pvsyst}}</h2>
              </div>
              <div class="cashflow-display__single">
                  <span class="cashflow-display__title">{{ t('probalance',{},{locale:getLocal}) }}</span>
                  <h2 class="font-green cashflow-display__amount">{{Production}}</h2>
              </div>
        
          </div>
        </div>
        <div class="col-12">
          <div class="row my-3" >
            <div class="col-12 min-hight">
              <ChartJs type="bar" :data="ChartData" :option="ChartOptions" :key="ChartData" />
            </div>
            
          </div>
        </div>
      </div>
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import ChartJs from '@/components/partial/ChartJs.vue';
import { useI18n } from 'vue-i18n';
export default {
  name: 'PvsystChart',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  props: [
    "ChartData",
    "Pvsyst",
    "Production",
    "Unit",
  ],
  components: {
    ChartJs
  },
  data: function () {
    return {
      listData:'',
      ChartOptions:{
        barValueSpacing: 20,
        legend: {
          display: true,
          position: 'bottom',
          align: "start",
          labels: {
            usePointStyle:true,
            fontColor: '#5A5F7D',
            color:true,
            borderWidth: 0,
            
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              callback:((value, index, values) => `${value} ${this.Unit}`)
            }
          }]
        }
      }
      
    }
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .card-progress{
//   background: #fff;
//   box-shadow: 0 10px 30px rgb(146 153 184 / 15%);
//   border-radius: 10px;
//   padding: 25px;
// }
.card-overview-progress{
  margin-top:0px !important;
  min-height: 50px !important;
  max-width: 100% !important;
  .card-body{
    margin-top:0px !important;
    padding:0px;
  }
}

.card-progress__summerys{
  margin-bottom: 10px;
}
.min-hight{
  min-height: 300px;
}
.cashflow-display__single{
  margin-bottom: 0px !important;
}
</style>
